<template>
  <div>
    <div
      class="w-full ml-auto mr-auto"
      :style="widthResize"
    >
      <div
        v-if="!uploadGraph"
        :key="count"
      >
        <div v-if="notGraph">
          <div class="mt-10px mb-6">
            <apex-chart
              class="isSingleItem"
              :type="periodList == 'thisGis' ? 'bar' : 'line'"
              :options="chartOptions"
              :series="series"
              height="303"
            />
          </div>
        </div>
        <div
          v-else
          class="overflow-hidden"
          style="height: 223px; position: relative;margin-bottom: 52px;"
        >
          <div
            width="100%"
            style="min-height: 238px;"
          >
            <div
              class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light"
              style="width: 476px; height: 223px;margin: auto;"
            >
              <svg
                width="476"
                height="223"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class="apexcharts-svg"
                transform="translate(0, 0)"
                style="background: transparent;margin-top: 26px;"
              >
                <g class="apexcharts-annotations" />
                <g class="apexcharts-inner apexcharts-graphical">
                  <defs />
                </g>
                <text
                  font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                  x="238"
                  y="129.5"
                  text-anchor="middle"
                  dominant-baseline="auto"
                  font-size="16px"
                  fill="RGBA(4,21,62,0.49)"
                  class="apexcharts-text "
                  style="font-family: 'Golos UI';, 'Trebuchet MS', Verdana, sans-serif; opacity: 1;"
                >
                  {{ text }}
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import Api from '@/api/Api';
import { formattedDate } from '@/helpers/utils';

const api = new Api();

export default {
  name: 'Dtp',
  components: {
    ApexChart
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    date: {
      type: Date,
      default: null
    },
    dateEnd: {
      type: Date,
      default: null
    },
    periodList: {
      type: String,
      default: 'thisGis'
    }
  },
  data() {
    return {
      monthDate: [],
      uploadGraph: false,
      widthResize: 'width: 100%;',
      notGraph: true,
      item: [],
      itemDel: [],
      daysItem: [],
      series: [],
      chartOptions: {},
      count: 0,
      text: 'Нет данных за выбранный период'
    };
  },
  // eslint-disable-next-line consistent-return
  created() {
    const _this = this;
    const items = _this.items?.filter(el => el != undefined);
    if (items?.length <= 0) {
      _this.notGraph = false;
    }
    if (!items) return [];
    const arr = [];
    const objects = items;

    let dat = null;
    for (const i in objects) {
      if (objects[i]?.details?.dates && objects[i].details.typeWork == 0) {
        const date = _this.formatDate(objects[i].details.dates);

        if (date == dat) {
          arr.push({ col: Number(1), date });
        } else {
          arr.push({ col: Number(1), date });
          dat = _this.formatDate(objects[i].details.dates);
        }
      }
    }
    arr.sort((a, b) => new Date(`01.${a.date}`) - new Date(`01.${b.date}`));
    let date = null;
    let id = 0;
    arr.forEach(item => {
      if (date == item.date) {
        _this.item[id] = _this.item[id] + item.col;
      } else {
        id++;
        _this.item[id] = item.col;
        _this.daysItem.push(item.date);
        date = item.date;
      }
    });
    _this.item.shift();
    _this.series = [{
      name: 'ДТП',
      data: this.item
    }];
    this.chartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 4
      },
      title: {
        text: '',
        align: 'center'
      },
      responsive: [{
        breakpoint: 380,
        options: {
          legend: {
            position: 'top',
            offsetX: 0,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        categories: this.daysItem,
        type: 'category',
        tickPlacement: 'between',
        labels: {
          offsetX: 0,
          rotate: -25,
          showDuplicates: false,
          hideOverlappingLabels: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400
          }
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        crosshairs: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      legend: {
        show: false
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      crosshairs: {
        show: false
      },
      yaxis: {
        labels: {
          show: true,
          formatter(val) {
            return val;
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400
          }
        }
      },
      colors: ['#BB9AF4']
    };
  },
  methods: {
    formatDate(date) {
      date = new Date(Number(date));
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${mm}.${yy}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
}

::v-deep .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0% 0% 0% 0% round 8px) !important;
}
</style>
