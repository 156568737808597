<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div
        class="mt-2 ml-auto flex content pointer"
        @click="upload = !upload"
      >
        <r-icon
          icon="close"
          size="20"
          fill="rocky"
        />
      </div>
      <div class="parent align-center">
        <h1>Статистика ДТП</h1>
        <div class="objects__filter">
          <div
            class="flex-1 items-center"
            style="max-width: 130px"
          >
            <drop-down-button
              :key="count"
              class="mt-4"
              @select="onChangePeriodOne"
              :checklist="periodListOne"
            >
              {{ selectedPeriodOne }}
            </drop-down-button>
          </div>
          <div
            class="flex-1 items-center"
            style="max-width: 250px"
          >
            <drop-down-button
              :key="count"
              class="mt-4"
              @select="onChangePeriod"
              :checklist="periodList"
            >
              {{ selectedPeriod }}
            </drop-down-button>
            <r-date-picker
              label=""
              class="cleaning_dateLighting"
              fill="rocky"
              style="margin-left: -80px;"
              v-model="period"
              @input="perodFnc"
              period
            />
          </div>
          <!-- <rir-button
            transparent
            class="flex align-center sulguni text-rocky ml-auto"
          >
            <rir-icon
              class="mr-2"
              icon="load"
            />
            Скачать отчет
          </rir-button> -->
        </div>
        <div
          class="container-upload"
          :key="count"
        >
          <dtp
            :period-list="selectedPeriodIdOne"
            :items="items"
            :key="'long'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dtp from '@/components/Dtp.vue';
import DropDownButton from '@/components/DropDownButton.vue';

export default {
  name: 'Graph',
  components: {
    Dtp,
    DropDownButton
  },
  data() {
    return {
      upload: false,
      count: 0,
      periodList: [
        { id: 'today', active: false, value: 'Сегодня' },
        { id: 'thisWeek', active: false, value: 'Неделя' },
        { id: 'thisMonth', active: true, value: 'Месяц' },
        { id: 'thisPeriod', active: false, value: 'Период' }
      ],
      period: null,
      selectedPeriod: 'Месяц',
      selectedPeriodId: 'thisMonth',
      periodListOne: [
        { id: 'thisGis', active: true, value: 'Гистограмма' },
        { id: 'thisGraph', active: false, value: 'График' }
      ],
      items: null,
      selectedPeriodOne: 'Гистограмма',
      selectedPeriodIdOne: 'thisGis'
    };
  },
  mounted() {
    this.$parent.$on('update', this.uploadContainer);
  },
  methods: {
    last(d, last) {
      this.items = !this.$store.state?.all?.length ? [] : this.$store.state.all;
      this.items = this.items.map(item => {
        if (item?.details?.dates) {
          if ((new Date(d).getTime() > Number(item.details.dates))
            && (new Date(last).getTime() < Number(item.details.dates))) {
            return item;
          }
        }
      });
    },
    onChangePeriod(e) {
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      if (e.id == 'today') {
        const d = new Date(new Date().setHours(0, 0, 0, 0));
        const last = new Date(new Date().setHours(23, 59, 59, 999));
        this.last(d, last);
      }
      if (e.id == 'thisWeek') {
        let lastWeek = new Date();
        lastWeek = new Date().getTime() - 604800000;

        let d = new Date();
        d = this.formatDate(d);
        this.last(d, lastWeek);
      }
      if (e.id == 'thisMonth') {
        let d = new Date();
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        d = this.formatDate(d);
        lastMonth = this.formatDate(new Date(lastMonth));
        this.last(d, lastMonth);
      }
      this.count++;
      if (e.id == 'thisPeriod') {
        const parent = document.querySelectorAll('.cleaning_dateLighting')[0];
        parent.querySelectorAll('.RDatePicker__input')[0].click();
      }
    },
    onChangePeriodOne(e) {
      this.periodListOne = this.periodListOne.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriodOne = e.value;
      this.selectedPeriodIdOne = e.id;
    },
    perodFnc() {
      const _this = this;
      if (this.selectedPeriodId == 'thisPeriod') {
        this.selectedPeriod = `${this.period[0].replace(/[-]/g, '.')}-${this.period[1].replace(/[-]/g, '.')}`;
        if (this.period != []) {
          this.items = !this.$store.state?.all?.length ? [] : this.$store.state.all;
          this.items = this.items.map(item => {
            if (item?.details?.dates) {
              if (new Date(new Date(_this.period[0]).setHours(0, 0, 0, 0)).getTime() <= Number(item.details.dates)
                && new Date(new Date(_this.period[1]).setHours(23, 59, 59, 999)).getTime() >= Number(item.details.dates)) {
                return item;
              }
            }
          });
        }
        this.count++;
      }
    },
    uploadContainer() {
      this.items = !this.$store.state?.all?.length ? [] : this.$store.state.all;
      this.periodList = this.periodList.map(el => ({ ...el, active: el.id == 'thisMonth' }));
      this.selectedPeriod = 'Месяц';
      this.selectedPeriodId = 'thisMonth';
      let d = new Date();
      let lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      d = this.formatDate(d);
      lastMonth = this.formatDate(new Date(lastMonth));
      this.last(d, lastMonth);
      this.count++;
      this.upload = true;
    },
    formatDate(date) {
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${yy}-${mm}-${dd}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload {
  background-color: white;
  z-index: 49;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 70%;
  height: 400px;
  top: 30%;
  margin-left: 15%;
}
.content {
  width: 25px;
}

.cleaning_dateLighting {
  visibility: hidden;
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.objects {
  &__filter {
    margin-top: 16px;
    display: flex;
  }
}
</style>
