export default {
  state: () => ({
    headings: [
      {
        title: 'Фото',
        field: 'photo'
      },
      {
        title: 'Тип',
        field: 'type'
      },
      {
        title: 'Адрес',
        field: 'address'
      },
      {
        title: 'Дата, время',
        field: 'start'
      },
      {
        title: 'Публикация',
        field: 'public'
      },
      {
        title: 'Статус',
        field: 'status'
      },
      {
        title: 'Видеозапись',
        field: 'video'
      },
      {
        title: 'Пользователь',
        field: 'user'
      }
    ]
  })
};
