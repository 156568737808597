import Vue from 'vue';
import VueRouter from 'vue-router';
import EditDtp from '@/views/EditDtp';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import NotFoundPage from '../views/NotFoundPage';
import NewDtp from '../views/NewDtp';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,

    children: [
      {
        name: 'index',
        path: '',
        component: ObjectList
      },
      {
        name: 'mod',
        path: '',
        component: ObjectList
      },
      {
        path: 'new-dtp',
        component: NewDtp
      },
      {
        path: 'edit-dtp/:id',
        name: 'edit-dtp',
        component: EditDtp
      },
      {
        path: 'edit-dtp/',
        name: 'edit',
        component: EditDtp
      },
      { path: '*', name: 'NotFoundPage', component: NotFoundPage }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
