<template>
  <div class="r-map-balloon">
    <div
      class="pointer"
      @click="link"
    >
      <div class="pointer">
        <div
          v-if="marker.photo"
          class="r-map-balloon__imges"
        >
          <img
            class="r-map-balloon__img"
            :src="marker.photo"
            :alt="1"
          >
        </div>
        <div class="r-map-balloon__info padding_b">
          <p class="roquefort">
            {{ marker.dtp }}
          </p>
          <p class="mt-1 сaprino opacity-72">
            {{ marker.address }}
          </p>
          <div
            class="flex align-start mt-3 mozzarella"
            style="color: #04153E"
          >
            <r-icon
              icon="calendar"
              fill="rocky"
              class="mr-2"
              size="16"
            />
            {{ formatDate(marker.date) + ' в ' + formatTime(marker.date) }}
          </div>
          <p
            class="pointer mt-3 mb-3 matrix--text sulguni"
            v-if="marker.public"
          >
            Опубликовано
          </p>
          <p
            class="pointer mt-3 mb-3 rocky--text sulguni"
            v-else
          >
            Не опубликовано
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownCheckList from '@/components/DropDownCheckList';

export default {
  name: 'BalloonCard',
  components: {
    DropDownCheckList
  },
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      id: 0,
      selectedPeriod: 'Не опубликовано',
      periodList: [
        { id: 'public', active: false, value: 'Опубликовано' },
        { id: 'notPublic', active: true, value: 'Не опубликовано' }
      ]
    };
  },
  methods: {
    formatDate(date) {
      date = new Date(Number(date));
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    formatTime(date) {
      date = new Date(Number(date));
      let h = date.getHours();
      if (h < 10) h = `0${h}`;
      let m = date.getMinutes();
      if (m < 10) m = `0${m}`;

      return `${h}:${m}`;
    },
    link() {
      const _this = this;
      const r = this.$router.resolve({
        name: 'edit-dtp',
        params: { id: _this.marker.id }
      });
      window.location.assign(r.href);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  max-height: 400px;
  &__imges {
    width: 100%;
    height: 122px;
    overflow: hidden;
  }
  &__img {
    width: 100%;
  }
  &__info {
    font-family: 'Golos UI';
    padding: 16px;
  }
}
.pointer{
  cursor: pointer;
}
.padding_b{
  padding-bottom: 0px;
}
.padding_t{
  padding-top: 0px;
}
</style>
