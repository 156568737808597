import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import contracts from './modules/contracts';

const api = new Api();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    all: null,
    allId: null,
    isObjectLoading: null
  },
  mutations: {
    setAll(state, res) {
      state.all = res;
    },
    setAllId(state, res) {
      state.allId = res;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    }
  },
  getters: {
  },
  actions: {
    async getAll(ctx) {
      const res = await api.getAccidens();
      ctx.commit('setObjectLoading', true);
      ctx.commit('setAll', res.all);

      ctx.commit('setObjectLoading', false);
      return res;
    },
    async getAllId(ctx, id) {
      const res = await api.getAccidensId(JSON.stringify({ id, all: 1 }));
      ctx.commit('setObjectLoading', true);
      ctx.commit('setAllId', res.all);

      ctx.commit('setObjectLoading', false);
      return res;
    }
  },
  modules: {
    contracts
  }
});
