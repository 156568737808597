<template>
  <div
    ref="content"
    class="button_fly"
  >
    <admin-header
      :title="'Редактирование события'"
      :is-save="!isSave"
    />
    <Ladoer v-if="isLoading" />
    <template v-else>
      <div
        class="form mt-8 flex"
        :key="count"
      >
        <div class="flex flex-1 flex-column">
          <r-date-picker
            is-time
            :is-input="false"
            @input="isChange"
            v-model="formData.details.dates"
            label="Дата"
            :is-clear-model="true"
            :click-close="true"
          />
          <r-time-picker
            v-model="formData.details.times"
            label="Время"
            class="mt-4"
          />
          <r-select
            label="Тип"
            class="mt-4"
            v-model="formData.details.typeWork"
            @input="isChangeDtp"
            :items="selectedType"
          />
          <r-select
            :key="countDtp"
            label="Вид"
            class="mt-4"
            v-model="formData.details.selectWorkAndDtp"
            @input="isChange"
            :items="formData.details.typeWork == 0 ? selectedDtp : selectedWork"
          />
          <div class="flex">
            <r-select
              label="Статус"
              class="flex flex-1 flex-column mt-8"
              v-model="formData.details.selectStatus"
              @input="isChange"
              :items="selectedStatus"
            />
            <r-select
              label="Актуальность, ч"
              :readonly="formData.details.selectStatus == 1 ? true : false"
              class="flex flex-column flex-1 mt-8 ml-4"
              v-model="formData.details.time"
              @input="isChange"
              :items="selectedTime"
            />
          </div>
          <r-select
            label="Заблокированные ряды, шт"
            class="mt-4"
            v-model="formData.lane"
            @input="isChange"
            :items="selectedTrass"
          />
          <r-textarea
            class="mt-8"
            v-model="formData.comment"
            @input="isChange"
            label="Комментарий"
            :rows="8"
          />
          <h2 class="taleggio mt-10 mb-4">
            Фотографии
          </h2>
          <upload-file-input
            :count-file="5"
            upload-url="/uploadfile/index.php"
            :value="formData.details.images"
            @input-file="file($event, 'img')"
            :accept-file="['image/png', 'image/jpeg', 'image/gif']"
            @input="uploadFile($event, 'img')"
            sub-title="или перетяните сюда до 10 файлов, общим объёмом не более 50 МБайт"
          />
          <h2 class="taleggio mt-10 mb-4">
            Видеозаписи
          </h2>
          <upload-file-input
            :count-file="10"
            upload-url="/uploadfile/index.php"
            :value="formData.details.video"
            @input-file="file($event, 'video')"
            :accept-file="['video/x-msvideo', 'video/x-flv', 'video/quicktime', 'video/mp4', 'video/mpeg', 'video/webm', 'video/x-ms-wmv', 'video/x-m4v']"
            @input="uploadFile($event, 'video')"
            sub-title="или перетяните сюда до 10 файлов, общим объёмом не более 50 МБайт"
          />
          <h2 class="taleggio mt-10">
            Автор
          </h2>
          <r-input
            class="mt-4 flex-1 mb-6"
            v-model="formData.details.fio"
            @input="isChange"
            label="Фамилия Имя Отчество модератора"
          />
          <div
            class="flex align-center custom mt-2 mb-60px pointer fargo-hover pointer"
            @click="deleteObj"
          >
            <r-icon
              icon="delete"
              fill="fargo"
              size="16"
            />
            <div class="fargo--text ml-2 pointer">
              Удалить событие
            </div>
          </div>
        </div>
        <div
          class="ml-6 flex flex-column flex-1"
          style="max-width: 460px"
        >
          <rir-map
            :center="formData.lat ? [formData.lat, formData.lng] : $cityCenter"
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-6"
          >
            <ymap-marker
              :coords="formData.lat ? [formData.lat, formData.lng] : $cityCenter"
              :marker-id="'marker'"
              :icon="$markerIconPoint()"
              :options="{
                hideIconOnBalloonOpen: false,
                draggable: true
              }"
              @dragend="onDragMarker"
            />
          </rir-map>

          <div
            class="mt-2"
            style="width: 100%;"
          >
            <r-input
              label="Адрес"
              v-model="formData.details.address"
              @input="isChange"
              @blur="searchAddress(formData.details.address)"
              @onPressEnter="searchAddress(formData.details.address)"
              :is-clear="true"
              before-icon="search"
              :is-clear-model-string="true"
            />
          </div>
        </div>
      </div>
      <div
        class="button_container flex align-center"
      >
        <r-button
          class="mr-6"
          :disabled="isLoading || isSave"
          @click="submit(true)"
          width="wide"
          title="Опубликовать"
        />
        <r-button
          color="secondary"
          :disabled="isLoading || isSave"
          @click="submit(false)"
          width="wide"
          title="Сохранить"
        />
      </div>
    </template>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
    <save ref="childComponent" />
  </div>
</template>

<script>
import { formattedFiles, getFileSize } from '@/helpers/utils';
import Save from '@/components/Save.vue';
import UploadFileInput from '@/components/UploadFileInput.vue';
import AdminHeader from '../components/AdminHeader';
import RirMap from '../components/RirMap';
import Api from '../api/Api';

const api = new Api();

export default {
  name: 'EditDtp',
  components: {
    UploadFileInput,
    AdminHeader,
    RirMap,
    Save
  },
  data() {
    return {
      selectedTime: [],
      time: 0,
      count: 0,
      countDtp: 0,
      formData: {
        approved: 1,
        id: -1,
        author: '',
        comment: '',
        details: {
          times: '00:00',
          selectWorkAndDtp: 1,
          dates: null,
          time: 1,
          typeWork: 0,
          selectStatus: 0,
          address: '',
          images: [],
          video: [],
          public: false,
          admin: true,
          fio: '',
          moderation: false
        },
        hidden: 0,
        lane: 0,
        lat: null,
        lng: null
      },
      selectedType: [
        {
          id: 0,
          title: 'ДТП'
        },
        {
          id: 1,
          title: 'Работы'
        }
      ],
      selectedWork: [
        {
          id: 1,
          title: 'Дорожные работы'
        },
        {
          id: 2,
          title: 'Неработающий светофор'
        },
        {
          id: 3,
          title: 'Разбитый асфальт/Грунтовая дорога'
        },
        {
          id: 4,
          title: 'Плановое перекрытие'
        },
        {
          id: 5,
          title: 'Другое'
        }
      ],
      selectedStatus: [
        {
          id: 0,
          title: 'Актуально'
        },
        {
          id: 1,
          title: 'Не актуально'
        }
      ],
      selectedTrass: [
        {
          id: 0,
          title: ''
        },
        {
          id: 1,
          title: '1'
        },
        {
          id: 2,
          title: '2'
        },
        {
          id: 3,
          title: '3'
        },
        {
          id: 4,
          title: '4'
        },
        {
          id: 5,
          title: '5'
        },
        {
          id: 6,
          title: '6'
        }
      ],
      selectedDtp: [
        {
          id: 1,
          title: 'Наезд на стоящее ТС'
        },
        {
          id: 2,
          title: 'Столкновение'
        },
        {
          id: 3,
          title: 'Наезд на препятствие'
        },
        {
          id: 4,
          title: 'Наезд на пешехода'
        },
        {
          id: 5,
          title: 'Наезд на велосипедиста'
        },
        {
          id: 6,
          title: 'Наезд на животное'
        },
        {
          id: 7,
          title: 'ДТП с перевозимым грузом'
        },
        {
          id: 8,
          title: 'Иное ДТП'
        }
      ],
      isSave: true,
      flyButton: '',
      isLoading: false,
      initialData: {}
    };
  },
  computed: {
  },
  updated() {
    const parent = document.querySelector('.file__reload');
    const elems = parent.querySelectorAll('.rir-file-list__size');
    let count = 0;
    this.$nextTick(() => {
      this.formData.details.video.map(el => {
        elems[count].innerHTML = getFileSize(el);
        count++;
      });
    });
  },
  async mounted() {
    const _this = this;
    await _this.$store.dispatch('getAllId', _this.$route.params.id).then(item => {
      for (let i = 1; i <= 24; i++) {
        _this.selectedTime.push({
          id: i,
          title: i
        });
      }
      _this.formData = item.all[0];
      _this.formData.details.dates = _this.dateFilter(_this.formData.details.dates);
      _this.formData.lane = Number(_this.formData.lane);
    });
    // await api.getUser().then(function (item) {
    //  _this.formData.details.fio = item.fio;
    // });
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
  },
  methods: {
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${yy}-${mm}-${dd}`;
    },
    deleteObj() {
      const info = 'delete';
      const item = { info, id: this.$route.params.id };
      this.$emit('updateDelete', item);
    },
    isChangeDtp() {
      if (this.formData.details.dates != null) {
        this.isSave = false;
      }
      this.formData.details.selectWorkAndDtp = 1;
      this.countDtp++;
    },
    isChange() {
      if (this.formData.details.dates != null) {
        this.isSave = false;
      }
    },
    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.searchAddress(coords);
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new Api();
      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        this.formData.lat = address[0];
        this.formData.lng = address[1];
      }
      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.formData.details.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.formData.details.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.formData.details.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.formData.lat = res.lat;
        this.formData.lng = res.lng;
      }
    },
    onDragMarker(e) {
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.coordinates = coords;
        this.searchAddress(this.coordinates);
        if (this.formData.details.dates != null) {
          this.isSave = false;
        }
      }
    },
    uploadFile(files, type) {
      const _this = this;
      if (!files?.length) {
        _this.files = [];
        return;
      }
      _this.formattedFiles(files, type);
    },
    file(files, type) {
      if (type === 'img') {
        this.formData.details.images = [];
      } else {
        this.formData.details.video = [];
      }
      this.formattedFiles(files, type);
    },
    formattedFiles(files, type) {
      const _this = this;
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.originalName, // .split('.')[0]
            filename: el.originalName,
            icon: type === 'image' ? 'video' : false,
            url: el.url
          }));

          if (type === 'img') {
            _this.formData.details.images.push(...fileList);
          } else {
            _this.formData.details.video.push(...fileList);
          }
        } else if (type === 'img') {
          _this.formData.details.images.push(el);
        } else {
          _this.formData.details.video.push(el);
        }
      });
    },
    submit(value) {
      this.formData.details.public = value;
      const item = this.formData;
      if (item.details.dates) {
        const date = new Date(`${item.details.dates} ${item.details.times}`);
        const milliseconds = date.getTime();
        item.details.dates = milliseconds;
      }
      const api = new Api();
      api.getSaveId(item).then(result => {
        const r = this.$router.resolve({
          name: 'index',
          params: {}
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    },
    loadFiles(files) {
      this.formData.details.images = formattedFiles(files);
      this.isChange();
    },
    loadFilesVideo(files) {
      this.formData.details.video = formattedFiles(files);
      this.isChange();
    }
  }
};
</script>

<style scoped lang="scss">
.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-textarea__label {
  color: rgba(4, 21, 62, 0.32);
}

.grid {
  grid-template-columns: 150px auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  align-items: center;
}

::v-deep .rir-textarea__textarea:focus {
  background: transparent !important;
}
::v-deep .rir-popover__activator .rir-select.disabled{
  opacity: .72!important;
  pointer-events: none!important;
}
::v-deep .rir-input.readonly:not(.rir-select){
  opacity: .72!important;
}
</style>
<style>
.rir-date-picker__time {
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  background: #fff;
  border-radius: 16px;
  padding: 20px 0;
  box-shadow: 0 8px 24px rgba(19, 57, 134, .16);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 112px;
  box-sizing: border-box;
}

.rir-date-picker__time p {
  margin: 0 0 16px 0;
  color: #04153e;
  opacity: .48
}

.rir-date-picker__time .hour {
  margin-left: 16px
}

.rir-date-picker__time .hour, .rir-date-picker__time .minutes {
  padding-right: 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  scrollbar-color: rgba(4, 21, 62, .08) transparent
}

.rir-date-picker__time .hour::-webkit-scrollbar-track, .rir-date-picker__time .minutes::-webkit-scrollbar-track {
  width: 4px;
  background: #fff
}

.rir-date-picker__time .hour::-webkit-scrollbar, .rir-date-picker__time .hour::-webkit-scrollbar-thumb, .rir-date-picker__time .minutes::-webkit-scrollbar, .rir-date-picker__time .minutes::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72
}

.rir-date-picker__time .hour > span, .rir-date-picker__time .minutes > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  color: #113079;
  margin-bottom: 8px;
  border-radius: 4px;
  flex-shrink: 0
}

.rir-date-picker__time .hour > span.active, .rir-date-picker__time .minutes > span.active {
  color: #fff;
  background: #3d75e4
}

.rir-date-picker__time .hour > span:not(.active):hover, .rir-date-picker__time .minutes > span:not(.active):hover {
  background-color: #e4edfb;
  color: #3d75e4
}

.rir-date-picker__time_wrapper {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 20px)
}
.rir-date-picker__time>div {
  display: flex;
  flex-direction: initial !important;
  width: 100% !important;
  overflow: auto;
}
.rir-date-picker__content {
  overflow: visible !important;
}
</style>
<style>
.rocky {
  background-color: unset;
}
.fargo {
  background-color: unset;
}
</style>
