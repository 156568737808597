<template>
  <div>
    <template>
      <dtp-table
        :list="[]"
        v-if="selectedView === 'table'"
        :items="items"
      />
    </template>
  </div>
</template>

<script>
import DropDownCheckList from './DropDownCheckList';
import DtpTable from './DtpTable';

export default {
  name: 'TabModeration',
  components: {
    DropDownCheckList,
    DtpTable
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      id: 0,
      selectedView: 'table',
      selectedSortType: 0,
      searchText: '',
      cadastralNum: '',
      isYear: false,
      list: [],
      sortTypes: [
        {
          id: 0,
          value: 'Все'
        },
        {
          id: 1,
          value: 'ДТП'
        },
        {
          id: 2,
          value: 'Работы'
        }
      ],
      isShowMap: false,
      years: [],
      contractsForMap: [],
      selectedSortTypeLaw: 0,
      sortTypesLaw: [
        {
          id: 0,
          value: 'Все'
        },
        {
          id: 1,
          value: 'Житель'
        },
        {
          id: 2,
          value: 'Администратор'
        }
      ]
    };
  },
  computed: {
    countObjects() {
      return this.filteredObjects?.length || 0;
    }
  },
  async mounted() {
    if (this.items.length !== 0) {
      document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
    }
  },
  methods: {
    onChangePeriodId(id) {
      const _this = this;

      _this.id = id;
    },
    onChangePeriod(e) {
      const _this = this;

      _this.list[_this.id].public.periodList = _this.list[_this.id].public.periodList.map(el => ({
        ...el,
        active: el.value === e.value
      }));
      _this.list[_this.id].public.selectedPeriod = e.value;
    },
    mapClick(item) {
      this.selectedView = item;
      if (item === 'map') {
        document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
      } else {
        document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
      }
    }
  }
};
</script>

<style scoped>
::v-deep .ymaps-2-1-79-balloon__layout,
::v-deep .ymaps-2-1-79-balloon__content{
  background: transparent!important;
}
::v-deep .ymaps-2-1-79-balloon{
  box-shadow: none!important;
}
</style>
