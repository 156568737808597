import { render, staticRenderFns } from "./AdminHeader.vue?vue&type=template&id=22026caa&scoped=true&"
import script from "./AdminHeader.vue?vue&type=script&lang=js&"
export * from "./AdminHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22026caa",
  null
  
)

export default component.exports