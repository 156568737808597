<template>
  <scroll-table
    :headings="$store.state.contracts.headings"
    :list-item="items"
    row-link="/contract/"
    :columns-grid="tableGridColumns"
    start-sorted-column="title"
  />
</template>

<script>
import ScrollTable from './Table';

export default {
  name: 'DtpTable',
  components: {
    ScrollTable
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: [],
      tableGridColumns: [
        '100px',
        '296px',
        '272px',
        '120px',
        '220px',
        '68px',
        '108px',
        '284px'
      ]
    };
  }
};
</script>

<style scoped></style>
