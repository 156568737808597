<template>
  <div
    :class="{'opacity-48': disabled}"
    v-click-outside="clickOutside"
  >
    <div
      class="flex align-center sulguni rocky--text pointer"
      @click="disabled ? null : showDropdown = !showDropdown"
      v-for="item in checklist"
    >
      <template>
        <p
          :class="[
            'pointer sulguni ',
            { 'matrix--text': item.id == 'public' },
            { 'rocky--text': item.id == 'notPublic' }]"
          v-if="item.active"
        >
          <slot />
        </p>
      </template>
      <svg
        :style="showDropdown ? 'transform: rotate(180deg);' : ''"
        v-if="item.active"
        class="ml-2 mt-1"
        width="8"
        height="4"
        viewBox="0 0 8 4"
        fill="#3d75e4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.00153 1.82999L6.45177 0.172415C6.90639 -0.135128 7.52351 -0.014819 7.83015 0.441133C8.13679 0.897084 8.01684 1.51602 7.56222 1.82356L4.59679 3.82965C4.40671 3.95824 4.18821 4.01203 3.97588 3.99776C3.77892 4.00141 3.57892 3.94646 3.40321 3.82758L0.437779 1.8215C-0.016836 1.51395 -0.136792 0.895018 0.169849 0.439066C0.476491 -0.0168856 1.09361 -0.137194 1.54823 0.170349L4.00153 1.82999Z"
        />
      </svg>
    </div>
    <div
      class="list"
      v-show="showDropdown"
    >
      <div
        :class="['item', { 'item--active': item.active }]"
        @click="!item.disabled && selectItem(item)"
        v-for="item in checklist"
        :key="item.id"
      >
        <r-icon
          v-if="item.icon"
          :class="['mr-3 icn']"
          :icon="item.icon"
        />
        <span class="sulguni">{{ item.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownButton',
  directives: {
    ClickOutside
  },
  props: {
    checklist: {
      type: Array
    },
    id: {
      type: Number
    },
    disabled: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      model: ['2022'],
      showDropdown: false
    };
  },
  mounted() {

  },
  methods: {
    clickOutside() {
      this.showDropdown = false;
    },
    selectItem(item) {
      this.showDropdown = false;
      this.$emit('selectId', this.id);
      this.$emit('select', item);
    }
  }
};
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}
.list {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 9px 24px rgba(17, 48, 121, 0.18);
  z-index: 10000;
  border-radius: 8px;
}
.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;
  &:hover {
    opacity: 1;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      background-color: #3d75e4;
      height: calc(100% - 8px);
      width: 4px;
      top: 4px;
      left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.item--active {
  opacity: 1;
  &::before {
    content: '';
    position: absolute;
    background-color: #3d75e4;
    height: calc(100% - 8px);
    width: 4px;
    top: 4px;
    left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
::v-deep .rir-drop-down__content {
  top: 100%;
  left: 0;
}
</style>
